/* You can add global styles to this file, and also import other style files */
@import "app/scss/ui/_variable.scss";
@import "app/scss/ui/_mixins";
@import "~bootstrap/scss/bootstrap";
/* You can add global styles to this file, and also import other style files */
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  --logo-color: rgb(89, 74, 45);
  --logo-color1: #003471;

  --navbar-color: #272727;
  --navbar-hover-color: #897343;
  --navbar-bg: #292929;
  --red-navbar-color: #891910;
  --navbar-text-color: #ffffff;

  --background-color: #ffffff;
  --footer-color: #891910;
  --footer-text: rgb(172, 145, 85);
  --footer-text-down: #f25961;
  --footer-text-up: #3a9d37;
  --title-area: #41655b;
  --title-area2: #282828;
  --title-color: #121212;
  --title-color2: #131722;
  --title-black-color: #ffffff;
  --title-black-color2: #ffffff;
  --scrollbar-color: #41655b;
  --scrollbar-hover: #41655b;

  --fas-icon: #e7e7e7;
  --fas-icon-hover: #ffffff;
  --prev-next-hover: rgba(255, 255, 255, 0.8);

  --btn-name-color: #b98d26;
  --btn-name-hover: #801a11;
  --btn-name-text: #ffffff;

  --grid-text: #cd5c5c;
  --grid-text2: #cd5c5c;

  --bg-white-color: #292929;
  --bg-white-color2: #181818;
  --bg-black-color: #fefefe;
  --background-tertiary-shadow: 0 20px 30px rgba(255, 255, 255, 0.1);
  --box-tertiary-shadow: 0 0 50px rgba(255, 255, 255, 0.5);

  --logo-image: url(assets/img/footer-logo1.png);
  --chart-theme: light;
  --banner-image: url(assets/img/banner1white.jpg);
  --banner-image2: url(assets/img/banner2dark.jpg);

  --phone-image: url(assets/img/phone2.png);
  --applogo-image: url(assets/img/applogo1.png);

  --content-icon1: url(assets/img/1-dark.png);
  --content-icon2: url(assets/img/2-dark.png);
  --content-icon3: url(assets/img/3-dark.png);
  --content-icon4: url(assets/img/6-dark.png);
  --content-bg: #ededed;

}


body {
  font-size: .9rem;
  overflow: auto;
  //font-family: Calibri Regular;
  font-family: Muli, Helvetica Neue, sans-serif;
}

html {
  margin: 0;
}

html, body {
  background-color: var(--bg-white-color);
}
