/*
 * Copyright  Bolt Insight Limited. All Rights Reserved.
 *
 * Save to the extent permitted by law, you may not use, copy, modify,
 * distribute or create derivative works of this material or any part
 * of it without the prior written consent of Bolt Insight Limited.
 * Any reproduction of this material must contain this notice.
 */

 @mixin border-radius($radius: 4px) {
    border-radius: $radius;
    -moz-border-radius: $radius;
    -webkit-border-radius: $radius;
  }
  
  @mixin border-top-radius($radius: 4px) {
    -webkit-border-top-left-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    -moz-border-radius-topleft: $radius;
    -moz-border-radius-topright: $radius;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
  }
  
  @mixin border-bottom-radius($radius: 4px) {
    -webkit-border-bottom-left-radius: $radius;
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-radius-bottomleft: $radius;
    -moz-border-radius-bottomright: $radius;
    border-bottom-left-radius: $radius;
    border-bottom-right-radius: $radius;
  }
  
  @mixin border-right-radius($radius: 4px) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-radius-bottomright: $radius;
    border-bottom-right-radius: $radius;
    -webkit-border-top-right-radius: $radius;
    -moz-border-radius-topright: $radius;
    border-top-right-radius: $radius;
  }
  
  @mixin border-left-radius($radius: 4px) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-radius-bottomleft: $radius;
    border-bottom-left-radius: $radius;
    -webkit-border-top-left-radius: $radius;
    -moz-border-radius-topleft: $radius;
    border-top-left-radius: $radius;
  }
  
  @mixin border-bottom-left-radius($radius: 4px) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-radius-bottomleft: $radius;
    border-bottom-left-radius: $radius;
  }
  
  @mixin border-bottom-right-radius($radius: 4px) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-radius-bottomright: $radius;
    border-bottom-right-radius: $radius;
  }
  
  @mixin border-top-left-radius($radius: 4px) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-radius-topleft: $radius;
    border-top-left-radius: $radius;
  }
  
  @mixin border-top-right-radius($radius: 4px) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-radius-topright: $radius;
    border-top-right-radius: $radius;
  }